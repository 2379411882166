import React from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Seo";
import {Container, T3} from "../styles/Styles";
import {PostBody} from "../styles/Styles";
import Mikan from "mikanjs";

const hero = 'https://cms2.climacell.co/wp-content/uploads/2019/11/sensing-picks-up-feat.jpg'

export default ({location}) => (
    <Layout>
        <SEO
            title="政府データが見逃した事象を、どのようにバーチャルセンサーが抽出するのか | tomorrow.io"
            image={hero}
            pathname={location.pathname}
        />

        <Container py={5} maxWidth={800}>
            <T3 as="h1" mb={4} dangerouslySetInnerHTML={{__html: Mikan('政府データが見逃した事象を、どのようにバーチャルセンサーが抽出するのか')}}/>
            <PostBody>
                <p><img src={hero} alt="政府データが見逃した事象を、どのようにバーチャルセンサーが抽出するのか"/></p>
                <p>気象観測と計測は、現在気象学が扱う通貨のようなものです。しかしながら、この通貨は
                    <nobr>供給</nobr>
                    不足で、不均一に配分されており、世界中で、正確な気象予報がもっとも必要とされる地域に及んでいません。信頼のおける気象予報にアクセスが不足していることで、高い痛手を被っています。2018年度中、<a
                        href="https://reliefweb.int/report/world/natural-disasters-2018">315</a>件の自然災害事象によって、<a
                        href="https://reliefweb.int/report/world/natural-disasters-2018">11,804</a>人の死亡者が確認
                    <nobr>され</nobr>
                    、そして世界中で<a
                        href="https://reliefweb.int/report/world/natural-disasters-2018">6.800</a>万人が影響を受けました。経済的な損失は、約14兆5億円という巨額なコストを負いました。
                </p>

                <h4>展開とデータ：従来の気象ツールの問題点</h4>
                <p>
                    アクセスの欠如は、気象観測を物理的なハードウェアの展開に依存した結果です。では、
                    <nobr>なぜ</nobr>
                    もっと多くのハードウェアを展開できないのでしょう？それは従来の気象ツールである衛星やレーダー、気象観測所は、運営ならびに管理も非常にお金がかかる為、<a
                    href="https://www.tomorrow.io/microweather-forecastings-great-leap-forward">新興国では
                    <nobr>めったに</nobr>
                    導入することはありません。</a>
                </p>
                <p>
                    別の問題点は、従来の気象予報をする企業が頼るデータのタイプです。大概のデータは、
                    <nobr>政府</nobr>
                    が提供する情報を集めたものです。気象が破壊的な脅威となる重要な要素は、天候変化と共にのみ成長する、<a
                    href="https://www.tomorrow.io/saving-lives-with-the-weather-of-things">自然の予測不能性です</a>。通常は地域毎、数時間毎の更新である政府
                    <nobr>データ</nobr>
                    の限界は、近日明白になってきています。
                </p>

                <h4>もし気象が全てに影響するというのであれば、その全ては潜在的な
                    <nobr>気象センサー</nobr>
                    です。
                </h4>
                <p dangerouslySetInnerHTML={{__html: Mikan('よい知らせは、近年のテクノロジーの進歩によって、デジタル化で繋がっているこの世界を、巨大なバーチャル気象センサーにできたことです。このウェザー・オブ・シングスというアプローチに大変期待できるものは、今までにない新しいタイプのデータにより、より正確で、ロケーションに特化した気象予報モデルの構築に活用することができることです。')}}/>
                <p dangerouslySetInnerHTML={{__html: Mikan(`以下は、実行中のバーチャルセンサーの例です。全てに共通していることは、tomorrow.ioのテクノロジーが人々をアクティブな気象情報の発信者として位置づけているやり方です。`)}}/>
                <p><b>1.マイクロ波と衛星のリンク：</b><span dangerouslySetInnerHTML={{__html: Mikan(`一般都市の全体で、マイクロ派信号は複数の携帯電話基地局を通り、膨大な数の機器をサポートしています。その間、衛星信号は宇宙から絶え間なく放射されており、地上の至る所でひとつひとつの衛星テレビへ信号を届けています。このような信号は、ポイントからポイントへのネットワークをサポートし、病院、金融トレーダー、クラウド提供者、鉄道といった各機関のために構築されました。そしてこれらは、気象関連データの驚くべき新しい情報源となっています。`)}}/>
                </p>

                <p>
                    <img
                        className="aligncenter"
                        src="https://cms2.climacell.co/wp-content/uploads/2019/11/sensing-picks-up-1.png"
                        alt="Map"
                        width="480"
                        height="253"
                    />
                    <div>
                        <small
                            style={{color: '#666'}}>3千平方キロメートルにおける、従来の携帯電話ネットワークのトポロジーは、インドの都市部と郊外、農村集落をカバーします。ワイヤレスリンクの正確な座標は、ランダム化されています。</small>
                    </div>
                </p>

                <p>
                    <img
                        className="aligncenter"
                        src="https://cms2.climacell.co/wp-content/uploads/2019/11/sensing-picks-up-2x.png"
                        alt="Map"
                        width="479" height="235"
                    />
                </p>

                <p>
                    <b>2.コネクテッドカー：</b>私たち人間は唯一、ちょっとした局所的な霧雨であっても感知することができ、同時にワイパーを作動させます。このような情報は、私たちにとって世界で最も正確な雨マップとなります。ABSシステムは、どれほど道路が滑りやすいかを教えてくれ、霧用ライトの点灯は、低可視性を教えてくれる、といったように非常に正確な指標です。
                </p>
                <p>
                    <img
                        className="aligncenter"
                        src="https://cms2.climacell.co/wp-content/uploads/2019/11/sensing-picks-up-3.png"
                        alt="Map"
                        width="480"
                        height="314"
                    />
                    <div>
                        <small
                            style={{color: '#666'}}>米国の五大湖地域で、100万代台以上の接続された車による個別の温度測定は、例えばシカゴの沿岸では低い温度であるといったようなマイクロスケールの事象を明らかにしてくれます。</small>
                    </div>
                </p>

                <p>
                    <b>3.携帯電話機器：</b>tomorrow.ioができる、前例のない天候の観測にアクセスするために、厳しく匿名化されたデータと、携帯電話内のセンサーから得た情報をレバレッジします。もし携帯電話のバッテリーがそろって切れるようなら、外が寒いのを示唆しています。また、バーチャルセンサーテクノロジーは、携帯電話の気圧センサーを活用して、大気中で隠れた機能が明らかになるような総観図を目指します。
                </p>
                <p>
                    <img
                        className="aligncenter"
                        src="https://cms2.climacell.co/wp-content/uploads/2019/11/sensing-picks-up-4.gif"
                        alt="Map"
                        width="480"
                        height="294"
                    />
                    <div>
                        <small
                            style={{color: '#666'}}>米国の五大湖地域で、100万代台以上の接続された車による個別の温度測定は、例えばシカゴの沿岸では低い温度であるといったようなマイクロスケールの事象を明らかにしてくれます。</small>
                    </div>
                </p>

                <p>
                    <b>4. 道路ライブカメラ：</b>
                    <span dangerouslySetInnerHTML={{__html: Mikan(`バーチャル・センシング技術は、最新の画像処理と、屋外のライブ映像や、そして間もなく、何百万台ものドライブレコーダーから降水量のタイプ、激しさ、雲量と言った膨大なデータを消化するディープラーニングに利用されます。`)}}/>
                </p>
                <p>
                    <img
                        className="aligncenter"
                        src="https://cms2.climacell.co/wp-content/uploads/2019/11/sensing-picks-up-5.png"
                        alt="Map"
                        width="480"
                        height="294"
                    />
                </p>

                <h4 dangerouslySetInnerHTML={{__html: Mikan(`チャレンジ：バーチャルセンサーデータを気象インサイトに変える`)}}/>

                <p dangerouslySetInnerHTML={{__html: Mikan(`バーチャルセンシング技術は、長く開発され続け、間もなく電気バイク、スクーター、航空機といった分野にも広がります。地球上の膨大な数のセンサーから集めたデータのリバースエンジニアリングは、非常に正確で、ロケーションに特化し、気象情報を常にアップデートするような国において、入手することができるようになります。`)}}/>

                <p dangerouslySetInnerHTML={{__html: Mikan(`母なる大地が荒れ狂うような自然災害に対し、適切な時間をもって備えることが出来なかったような人々が、お金のかかるインフラと設備への投資ならびに管理をすることなしに、十分な時間をもって対応することができるようになります。`)}}/>
                <p>
                    <strong>
                        <a href="https://www.tomorrow.io/blog/microweather-forecastings-great-leap-forward/">
                            “MicroWeather: Forecasting’s Great Leap Forward”をもっと読む
                        </a>
                    </strong>
                </p>


            </PostBody>
        </Container>

    </Layout>
);

